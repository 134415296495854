.btn-container {
  border: none;
  padding: 1rem 1.5rem;
  background-color: var(--soft-white);
  transition: all 0.5s;
}
.btn-container-nav {
  padding: 0.8rem 1.2rem;
}

.btn-container-footer {
  background-color: var(--light-green);
}
.btn-container:hover {
  cursor: pointer;
  background-color: var(--dark-green-100);
  color: var(--white);
}

@media screen and (max-width: 891px) {
  .btn-display-mobile-none {
    display: none;
  }
}

@media screen and (max-width: 491px) {
  .btn-container {
    padding: 1.5rem 1.2rem;
  }
  .btn-padding-mobile {
    padding: 0.8rem 1rem;
  }
}
