.quote-one {
  display: flex;
  background: url("../assets/quoteImgOne.webp");
  background-repeat: no-repeat;
  background-size: cover;
}
.quote-text-top {
  padding-block: 7rem;
  font-family: "Cormorant Garamond";
  font-weight: 500;
  max-width: 105rem;
}

@media screen and (max-width: 1100px) {
  .quote-text-top {
    padding-inline: 2.5rem;
  }
}
