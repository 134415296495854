.services-parent {
  display: flex;
  background-color: var(--light-green);
  padding-block: 9.5rem;
  padding-inline: 10rem;
}
.services-header {
  font-family: "Cormorant Garamond";
  font-weight: 500;
}

@media screen and (max-width: 1100px) {
  .services-parent {
    padding-block: 4.5rem;
  }
}
@media screen and (max-width: 981px) {
  .services-parent {
    padding-inline: 0;
  }
}
