:root {
  /* Golbal CSS Variables used throughout website for removing redundancy & easier modifications */
  --light-green: #bdffad;
  --bright-green: #81ff61;
  --dark-green-100: #77b368;
  --dark-green-400: #5f8057;
  --soft-white: #fffee5;
  --black: #000000;
  --white: #ffffff;
  --light-black: rgba(0, 0, 0, 0.35);
  --light-blue: #0078ce;
  --gradient-green: linear-gradient(
      0deg,
      rgba(189, 255, 173, 0.27),
      rgba(189, 255, 173, 0.27)
    ),
    linear-gradient(0deg, rgba(255, 254, 229, 0.72), rgba(255, 254, 229, 0.72));
  --gradient-black: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    linear-gradient(0deg, rgba(189, 255, 173, 0.5), rgba(189, 255, 173, 0.5));
}
