.nav-parent {
  padding: 2rem 3rem;
  position: relative;
}

.nav-logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.nav-container {
  z-index: 1;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
}

.nav-btn-container-mob {
  display: none;
}

.nav-links {
  margin-right: 3.5rem;
  cursor: pointer;
}

.nav-links-txt:hover {
  color: var(--dark-green-100);
}

.nav-btn-container-mob > .nav-links {
  margin-right: 0;
  display: none !important;
}

@media screen and (max-width: 891px) {
  .nav-btn-container {
    display: none;
  }
  .nav-btn-container-mob {
    display: flex;
  }
  .nav-btn-container-mob > .nav-links {
    display: flex !important;
  }
  .nav-btn-container-mob > div {
    margin-bottom: 1.5rem;
  }
  .nav-btn-container-mob > button {
    margin-bottom: 1.5rem;
  }
  .nav_list_mob {
    margin-top: 1rem;
  }
}
@media screen and (max-width: 491px) {
  .nav-btn-container {
    display: none;
  }
  .nav-parent {
    padding: 1.8rem 1.5rem;
  }
  .nav-logo-img {
    width: 80%;
  }
}
