.footer-parent {
  background-color: var(--soft-white);
  padding-block: 5rem;
}
.ftr-contact {
  font-family: "Cormorant Garamond";
  font-weight: 500;
}
.ftr-sec-2 {
  margin-top: 1.5rem;
}
.ftr-eght {
  max-width: 7rem;
}
.ftr-logo {
  margin-top: 2.5rem;
  max-width: 27rem;
}
.ftr-slogan {
  margin-top: 2.5rem;
}

.divider-footer-margins {
  padding-inline: 2rem;
}
