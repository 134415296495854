.home-parent {
  min-height: max(50rem, 90vh);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, rgba(189, 255, 173, 0.16), rgba(189, 255, 173, 0.16));
  position: relative;
  /* Will not allow the video to overflow the 
  container */
  overflow: hidden;

  /* Centering the container's content vertically 
  and horizontally */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.home-video-container {
  z-index: -1;
}
.home-text-shadow {
  text-shadow: 0rem 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
}

.home-eight {
  width: 10rem;
}

.home-asset-container {
  margin-top: 5%;
}
.divider-header-margins {
  padding-inline: 1rem;
}

@media screen and (max-width: 691px) {
  .home-parent {
    min-height: 100vh;
  }
  .home-asset-container {
    margin-top: 0;
  }
}
