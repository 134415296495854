/* CSS used too frequently added in global classes to reduce code redundancy */
.d-flex {
  display: flex;
}
.flex-center {
  display: flex;
  place-content: center;
  place-items: center;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-around {
  justify-content: space-around;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-end {
  align-items: flex-end;
}
.align-items-between {
  align-items: space-between;
}
.align-items-around {
  align-items: space-around;
}
.align-items-center {
  align-items: center;
}
.flex-column {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.f-wt400 {
  font-weight: 400;
}
.f-wt500 {
  font-weight: 500;
}
.f-wt600 {
  font-weight: 600;
}

.f-siz18-12 {
  font-size: 1.8rem;
}
.f-siz18-14 {
  font-size: 1.8rem;
}
.f-siz22-14 {
  font-size: 2.2rem;
}
.f-siz24-16 {
  font-size: 2.4rem;
}
.f-siz44-24 {
  font-size: 4.4rem;
}
.f-siz48-28 {
  font-size: 4.8rem;
}
.f-siz50-26 {
  font-size: 5rem;
}
.f-siz64-32 {
  font-size: 6.4rem;
}

.f-siz16 {
  font-size: 1.6rem;
}
.f-siz18 {
  font-size: 1.8rem;
}
.f-siz22 {
  font-size: 2.2rem;
}
.f-siz24 {
  font-size: 2.4rem;
}
.f-siz32 {
  font-size: 3.2rem;
}
.f-siz44 {
  font-size: 4.4rem;
}
.f-siz48 {
  font-size: 4.8rem;
}
.f-siz50 {
  font-size: 5rem;
}
.f-siz64 {
  font-size: 6.4rem;
}
.col-gap-10 {
  column-gap: 10rem;
}
.icon-w25 {
  width: 2.5rem;
}
.l-spacing-6 {
  letter-spacing: 0.06rem;
}
.pb-1_5 {
  padding-bottom: 1.5rem;
}
.pb-none {
  padding-bottom: 0 !important;
}
.text-center {
  text-align: center;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-decoration-none {
  text-decoration: none;
}
.text-underline {
  text-decoration: underline;
}
.divider-sm {
  height: 0.5rem;
  width: 100%;
  background-color: var(--white);
}
.divider-md {
  height: 0.7rem;
  width: 100%;
  background-color: var(--white);
}
.divider-lg {
  height: 1rem;
  width: 100%;
  background-color: var(--white);
}
.col-white {
  color: var(--white);
}

.col-black {
  color: var(--black);
}
.col-light-blue {
  color: var(--light-blue);
}

.col-light-green {
  color: var(--light-green);
}

.bg-light-black {
  background-color: var(--light-black);
}

.bg-light-green {
  background-color: var(--light-green);
}

.active {
  display: flex !important;
}

.inactive {
  display: none !important;
}

a {
  color: inherit;
  text-decoration: none;
}

.m-auto {
  margin: auto;
}

.mt-4 {
  margin-top: 4rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mb-1 {
  margin-bottom: 1rem !important;
}

#contact,
#about,
#services,
#home ::before {
  content: "";
  position: relative;
  top: -10rem;
}
.whatsapp-logo {
  position: fixed;
  bottom: 5%;
  right: 3%;
  width: 5rem;
}
.blurred-img {
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(1rem);
  -webkit-filter: blur(1rem);
}
.blurred-img.loaded {
  filter: blur(0);
  -webkit-filter: blur(0);
}
.blurred-img img {
  opacity: 0;
  transition: opacity 250ms ease-in-out;
  -webkit-transition: opacity 250ms ease-in-out;
  -moz-transition: opacity 250ms ease-in-out;
  -ms-transition: opacity 250ms ease-in-out;
  -o-transition: opacity 250ms ease-in-out;
}

.blurred-img::before {
  content: "";
  position: absolute;
  inset: 0;
  opacity: 0;
  /* animation: pulse 2.5s infinite; */
  background-color: white;
  /* -webkit-animation: pulse 2.5s infinite; */
}
/* 
@keyframes pulse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }
} */

.blurred-img .loaded::before {
  animation: none;
  content: none;
}

.blurred-img.loaded img {
  opacity: 1;
}

@media screen and (max-width: 691px) {
  .f-siz18-12 {
    font-size: 1.2rem;
  }
  .f-siz18-14 {
    font-size: 1.4rem;
  }
  .f-siz22-14 {
    font-size: 1.4rem;
  }
  .f-siz24-16 {
    font-size: 1.6rem;
  }
  .f-siz44-24 {
    font-size: 2.4rem;
  }
  .f-siz48-28 {
    font-size: 2.8rem;
  }
  .f-siz50-26 {
    font-size: 2.6rem;
  }
  .f-siz64-32 {
    font-size: 3.2rem;
  }
  .whatsapp-logo {
    position: fixed;
    width: 5rem;
    bottom: 3%;
    right: 10%;
  }
}
