.carousel-parent {
  /* max-width: 200rem; */
  height: 30rem;
  margin: auto;
}
.carousel-img {
  height: 30rem !important;
  width: 100vw !important;
  object-fit: cover;
}
