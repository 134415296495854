.parent-container {
  width: 100%;
}
.parent-container:not(:first-child) {
  max-width: 150rem;
}

.nav-container {
  position: fixed;
  top: 0;
  width: 100%;
}

.carousel-container {
  background-color: var(--light-green);
}
