.img-txt-container {
  max-width: 105rem;
  width: 100%;
  padding-top: 8rem;
  display: flex;
}
.add-txt-container {
  max-width: 105rem;
}
.love_shine {
  display: inline;
}
.service-img-container,
.about-img-container {
  max-width: 50rem;
  width: 50rem;
  height: 50rem;
  object-fit: cover;
}
.about-img-container {
  height: 100%;
}
.service-text {
  max-width: 50rem;
  width: 50rem;
}
.service-head {
  font-family: "Cormorant Garamond";
  font-weight: 500;
  font-size: clamp(3rem, -0.4023rem + 3.4682vw, 4.8rem);
  white-space: nowrap;
}
.service-body {
  padding-top: 2rem;
  line-height: 2;
  width: 100%;
}
.service-button {
  margin-top: 3.5rem;
}
.srvc-txt-pd-lft {
  padding-left: 10rem;
}
.srvc-txt-pd-rgt {
  padding-right: 10rem;
}

@media screen and (max-width: 1100px) {
  .img-txt-container {
    width: 90rem;
    padding-block: 4rem;
  }

  .service-img-container,
  .about-img-container {
    /* width: 50%; */
    max-width: 40rem;
  }

  .service-text {
    width: 50%;
  }
}

@media screen and (max-width: 981px) {
  .img-txt-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .service-img-container,
  .about-img-container {
    width: 100%;
    min-width: 31rem;
    min-height: 28rem;
  }

  .service-head {
    padding-top: 2rem;
    font-size: clamp(2.8rem, 2.7032rem + 0.3026vw, 3rem);
    text-align: center;
  }
  .service-body {
    padding-top: 1rem;
    max-width: 50rem;
  }
  .service-button {
    margin-top: 2rem;
  }
  .service-text,
  .add-txt-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 0 0 0;
  }
  .add-txt-container {
    /* width: 50rem; */
    padding-inline: 2rem;
  }
  .love_shine {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .img-txt-container {
    padding-inline: 2rem;
  }
}
