.quote-bottom {
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
}

.quote-vid-parent {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, rgba(189, 255, 173, 0.5), rgba(189, 255, 173, 0.5));
  position: relative;
  /* Will not allow the video to overflow the 
  container */
  overflow: hidden;

  /* Centering the container's content vertically 
  and horizontally */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10rem;
  padding-bottom: 6rem;
}
.quote-video-container {
  z-index: -1;
}

.quote-vid-parent > p {
  color: var(--white);
}
.quote-text-bottom {
  font-family: "Cormorant Garamond";
  font-weight: 500;
  max-width: 105rem;
}

@media screen and (max-width: 1100px) {
  .quote-text-bottom {
    padding-inline: 2.5rem;
  }
}
