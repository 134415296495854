/*  CSS Preset */

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

* {
  margin: 0;
}

/* percentage-based heights in the application */
html,
body {
  height: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 62.5%;
  /* overflow: hidden; */
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

video {
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* Removing built-in form typography styles */

input,
button,
textarea,
select {
  font: inherit;
}

/* Avoid text overflows */

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/* Creates a new stacking index */

#root {
  isolation: isolate;
}
